import { memo, useContext, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Route, Routes, useLocation, useMatch } from "react-router-dom";
import loadable from "@loadable/component";
import SmartDPSearchSummary, {
	AsyncSmartDPSearchFormContainer,
} from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import { AsyncBooking } from "app/routes";
import SmartDPBreadcrumb from "app/pages/SmartDP/Booking/SmartDPBreadcrumb/SmartDPBreadcrumb";
import SmartDPHotelDescriptionContainer from "app/pages/SmartDP/Quote/HotelDescription/SmartDPHotelDescriptionContainer";
import { QUOTATION_CODE_STATUS } from "app/constants";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import useHidePartnerCustomHeader from "app/utils/hooks/useHidePartnerCustomHeader";

import "./SmartDP.scss";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AppGlobalsContext from "app/AppGlobalsContext";

export const AsyncSmartDPSearch = loadable(() =>
	import(/* webpackChunkName: "smartdp-search" */ "app/pages/SmartDP/Search/SmartDPSearchContainer")
);

export const AsyncSmartDPListingContainer = loadable(() =>
	import(/* webpackChunkName: "smartdp-listing" */ "app/pages/SmartDP/Listing/SmartDPListingContainer")
);

const SmartDP = ({
	booking = {},
	homeBlocks = [],
	fetchSDPProductsStatus,
	fetchSDPAvailabilitiesStatus,
	handleSDPFormSubmit = () => {},
}) => {
	const { pathname } = useLocation();
	const { resolution } = useContext(AppGlobalsContext);

	const smartDPRef = useRef(null);

	const isSdpSearchPage = pathname.includes("sdp/search");

	const isSDPListingMapPage = pathname.includes("sdp/listing/map");

	const isSDPSearchPage = useMatch({
		path: "/sdp/search",
		end: true,
		caseSensitive: true,
	});
	const isSDPRootPage = useMatch({
		path: "/",
		end: true,
		caseSensitive: true,
	});

	const headerClass = classNames("smartdp", {
		"smartdp--search": isSDPRootPage || isSDPSearchPage,
	});

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const isSDPSummaryClassName = classNames("smartdp__summary", {
		"smartdp__summary--map-mobile": isSDPListingMapPage && isMobile,
	});
	const isSDPSummaryLoaderClassName = classNames("smartdp__summary-loader", {
		"smartdp__summary-loader--map-mobile": isSDPListingMapPage && isMobile,
	});

	useHidePartnerCustomHeader({ hide: !isSdpSearchPage });

	const isLoadingSDPListing = fetchSDPProductsStatus === QUOTATION_CODE_STATUS.LOADING;

	const isLoadingSdpQuote = fetchSDPAvailabilitiesStatus === QUOTATION_CODE_STATUS.LOADING;

	return (
		<div className={headerClass} ref={smartDPRef}>
			<Routes>
				<Route path="/search" element={null} />
				<Route
					path="/hotelDescription/:accommodationId/:code"
					element={
						<HeaderContainer
							showMenuItems
							showDialPhone={false}
							showBackBtn={false}
							disabledHomeRedirect={false}
						/>
					}
				/>
				<Route
					path="/listing/*"
					element={
						<HeaderContainer
							showMenuItems
							showDialPhone={false}
							showBackBtn
							disabledHomeRedirect={false}
							displayLoader={isLoadingSDPListing}
						/>
					}
				/>
				<Route
					path="/listing/map"
					element={
						isMobile ? (
							<></>
						) : (
							<HeaderContainer
								showMenuItems
								showDialPhone={false}
								showBackBtn
								disabledHomeRedirect={false}
								displayLoader={isLoadingSDPListing}
							/>
						)
					}
				/>
				<Route
					path="/booking/payment"
					element={
						<HeaderContainer
							showMenuItems={false}
							showDialPhone
							showBackBtn
							disabledHomeRedirect={true}
						/>
					}
				/>
				<Route
					path="/booking/quote"
					element={
						<HeaderContainer
							showMenuItems={false}
							showDialPhone
							showBackBtn
							disabledHomeRedirect={true}
							displayLoader={isLoadingSdpQuote}
						/>
					}
				/>
				<Route
					path="/booking/*"
					element={
						<HeaderContainer
							showMenuItems={false}
							showDialPhone={false}
							showBackBtn
							disabledHomeRedirect={false}
						/>
					}
				/>
				<Route path="/" element={null} />
			</Routes>

			<Routes>
				<Route
					path="/listing/*"
					element={
						!isMobile ? (
							<div className="smartdp__subheader">
								<AsyncSmartDPSearchFormContainer
									onSuccess={handleSDPFormSubmit}
									displayLoader={isLoadingSDPListing}
								/>
							</div>
						) : (
							<div className="smartdp__subheader">
								{isLoadingSDPListing ? (
									<div className={isSDPSummaryLoaderClassName}>
										<LoaderBar width={100} height={15} />
										<LoaderBar width={"90%"} height={15} />
										<LoaderBar width={150} height={15} />
									</div>
								) : (
									<div className={isSDPSummaryClassName}>
										<SmartDPSearchSummary
											occupancies={booking?.occupancies}
											departureCityLabel={booking?.departureCity?.label}
											departureResortLabel={booking?.destinationResort?.label}
										/>
									</div>
								)}
							</div>
						)
					}
				/>
			</Routes>

			<Routes>
				<Route
					path="/booking/*"
					element={
						<div className="smartdp__breadcrumb">
							<SmartDPBreadcrumb />
						</div>
					}
				/>
			</Routes>
			<Routes>
				<Route path="/search" element={<AsyncSmartDPSearch homeBlocks={homeBlocks} />} />
				<Route
					path="/hotelDescription/:accommodationId/:code"
					element={<SmartDPHotelDescriptionContainer printMode={true} />}
				/>
				<Route path="/listing/*" element={<AsyncSmartDPListingContainer />} />
				<Route path="/booking/*" element={<AsyncBooking />} />
				<Route
					path="/"
					element={<AsyncSmartDPSearch homeBlocks={homeBlocks} smartDPRef={smartDPRef} />}
				/>
			</Routes>
		</div>
	);
};

SmartDP.propTypes = {
	booking: PropTypes.object,
	homeBlocks: PropTypes.array,
	fetchSDPProductsStatus: PropTypes.string,
	fetchSDPAvailabilitiesStatus: PropTypes.string,
	handleSDPFormSubmit: PropTypes.func,
};

export default memo(SmartDP);
