import { connect } from "react-redux";
import { getPartnerMarketingHomeBlocks } from "app/reducers/partnerSelector";
import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import SmartDP from "app/pages/SmartDP/SmartDP";
import { bindActionCreators } from "redux";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";
import { getQuoteStatus } from "app/pages/Booking/Quotation/quotationSelectors";
import { SDP_PRODUCTS_FILTERS_KEY } from "app/constants";
import {
	resetAllSDPProductsFilter,
	unsetSDPProductsFilter,
} from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { useNavigate } from "react-router-dom";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";

const SmartDpContainer = props => {
	const navigate = useNavigate();

	const { fetchSDPFilters } = props;

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	const handleSDPFormSubmit = useCallback((data, path = "") => {
		const queryParams = buildSDPSearchPayload(data);
		unsetSDPProductsFilter(SDP_PRODUCTS_FILTERS_KEY.BUDGET);

		resetAllSDPProductsFilter();

		if (path.includes("/sdp/listing")) {
			navigate({ pathname: path, search: queryParams }, { replace: true });
		} else {
			navigate({ pathname: "/sdp/listing", search: queryParams });
		}
	}, []);

	return <SmartDP {...props} handleSDPFormSubmit={handleSDPFormSubmit} />;
};

SmartDpContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		booking: state.booking,
		homeBlocks: getPartnerMarketingHomeBlocks(state),
		fetchSDPProductsStatus: state.smartDP.status,
		fetchSDPAvailabilitiesStatus: getQuoteStatus(state),
		hasSdpSpecialOffer: state.smartDP.specialOffer, // true si le back renvoi au moins un vol remboursable
		specialOfferInfos: state.smartDP.specialOfferInfos,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPFilters,
			unsetSDPProductsFilter,
			resetAllSDPProductsFilter,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDpContainer);
